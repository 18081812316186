import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useSelector} from "react-redux"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {TableColumnProps} from "antd"
import Language from "language"
import {Search} from "components/search/Search"
import {useTextWithHighLight} from "components/dataSourceDoc/DataSource.hooks"
import {OptionListPagination} from "@biron-data/react-bqconf"
import {ConsolidatedEntryValue, loadDictionaryEntries} from "services/MetaModelService"
import {TableContainer} from "components/dataSourceDoc/DataSource.style"
import {getCurrentWorkspaceDatamodel} from "../../../redux/workspace.selector";
import {BironTable} from "@biron-data/react-components";

interface ValuesProps {
  dimensionCode: string
}

// eslint-disable-next-line react/display-name
const DetailsValues = memo<ValuesProps>(({dimensionCode}) => {
  const [options, setOptions] = useState<ConsolidatedEntryValue[]>([])
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const environmentId: number = useSelector(getCurrentEnvironmentId) as number
  const datamodel = useSelector(getCurrentWorkspaceDatamodel)
  const tableRef = useRef<any>(null)

  const query: (search?: string, pagination?: OptionListPagination) => Promise<ConsolidatedEntryValue[]> = useCallback((newSearch?: string, pagination?: OptionListPagination) => loadDictionaryEntries(environmentId, dimensionCode, datamodel?.code ? [datamodel.code] : [], newSearch, pagination), [datamodel?.code, dimensionCode, environmentId])

  const getOption = useCallback(() => {
    if (dimensionCode) {
      setLoading(true)
      query(undefined, {
        offset: 0,
        pageSize: 100,
      })
        .then(newOptions => {
          setOptions(newOptions)
          setLoading(false)
        }).catch(() => setLoading(false))
    }
  }, [dimensionCode, query])

  const onPaginationChange = useCallback((pagination: OptionListPagination) => {
    if (dimensionCode) {
      query(search, pagination)
        .then(newOptions => {
            setOptions(old => ([...old, ...newOptions]))
            setLoading(false)
          },
        ).catch(() => setLoading(false))
    }
  }, [dimensionCode, query, search])

  useEffect(() => {
    getOption()
  }, [dimensionCode, getOption, query])

  const getTextWithHighLight = useTextWithHighLight(search)

  const usageColumns: TableColumnProps<ConsolidatedEntryValue>[] = useMemo(() => [
    {
      title: Language.get(`admin.dataDoc.columns.value`),
      dataIndex: 'label',
      // render: (text: string) => getTextWithHighLight(text),
      width: '100%',
    },
  ], [])

  const onSearch = (newSearch: string) => {
    setSearch(newSearch)
    query(newSearch, {
      offset: 0,
      pageSize: 20,
    })
      .then(newOptions => {
          setOptions(newOptions)
          setLoading(false)
        tableRef.current?.resetPagination()
        },
      ).catch(() => setLoading(false))
  }

  return <TableContainer>
      <Search
        width={95}
        onChange={onSearch}/>
      <BironTable loading={loading}
                       columns={usageColumns}
                       dataSource={options}
                       rowHeight={34}
                  useVirtualGrid={true}
        //                pagination={{
        // offset: 0,
        // pageSize: 100,
        // onPaginationChange: (pagination: OptionListPagination) => {
        //   onPaginationChange(pagination)
        // },
        //                }}
        //                includeDefaultSorter={false}
      />
    </TableContainer>
})

export default DetailsValues