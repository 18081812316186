import {useCallback} from "react";
import {Roles, WorkspacesForAce} from "../components/admin/user/UserManager.types";
import useDispatch from "./useDispatch";
import {useSelector} from "react-redux";
import {getAvailablesForAce} from "../redux/workspace.selector";
import {getCurrentEnvironmentId} from "../redux/environment.selector";
import {UACWithAces} from "./useUACWithAces";

const useHandleUserAcesModification = () => {
  const dispatch = useDispatch()
  const availableWorkspacesForAce: WorkspacesForAce[] = useSelector(getAvailablesForAce)
  const environmentId = useSelector(getCurrentEnvironmentId)

  return useCallback(async (actualUac: UACWithAces, newFormData: { [p: string]: Roles[] | undefined }) => {
    const previousRolesPerTarget: {
      [target: string]: Roles[]
    } | undefined = actualUac.user.aces?.reduce((acc, {target, roles}) => ({
      ...acc,
      [target]: roles,
    }), {})

    const data: { [p: string]: Roles[] | undefined } = {
      [`environment:${environmentId}`]: [],
      ...availableWorkspacesForAce.reduce((acc, cur) => ({...acc, [`workspace:${cur.id}`]: []}), {}),
      // eslint-disable-next-line no-warning-comments
      ...(actualUac?.user.aces || []).reduce((acc, {target, roles}) => ({
        ...acc,
        [target]: roles,
      }), {}),
    }

    const newAcesFiltered = Object.entries({...data, ...newFormData})
      .reduce((acc: {
        target: string,
        roles: Roles[]
      }[], [target, roles]) => [
        ...acc,
        {
          target,
          roles: roles || [],
        },
      ], [])
      .filter(Boolean)
      // remove entry for a target when no role previously exists and none is newly selected
      .filter(({target, roles}) => roles.length > 0 || previousRolesPerTarget?.[target])
    await dispatch.user.updateUserAces({
      user: actualUac.user,
      aces: newAcesFiltered,
    });
  }, [environmentId, availableWorkspacesForAce, dispatch.user])
}

export default useHandleUserAcesModification