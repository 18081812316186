import React, {FunctionComponent, PropsWithChildren} from 'react'
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {FilterIcon} from "@heroicons/react/outline"
import {RuleObject} from "antd/lib/form"
import {RuleRender} from "rc-field-form/lib/interface"

interface WrapperProps extends PropsWithChildren {
  name: string
  rules?: (RuleObject | RuleRender)[]
  itemColumnWidth?: number
}

const DimensionFiltersWrapper: FunctionComponent<WrapperProps> = ({children, itemColumnWidth, name, rules}) => (
  <WrapperRoot {...{
    itemColumnWidth,
    icon: FilterIcon,
    label: "configuration-label-filters",
    description: "configuration-description-filters",
  }}>
    <WrapperItem name={name} rules={rules}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

export default DimensionFiltersWrapper
