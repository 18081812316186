import React, {PropsWithChildren} from 'react'
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"

export interface Props<T> extends PropsWithChildren {
  name: string
  initialValue?: T
}

const ChartFormatWrapper = <T, >({children, name, initialValue}: Props<T>) => <WrapperRoot>
  <WrapperItem name={name} initialValue={initialValue}>
    {children}
  </WrapperItem>
</WrapperRoot>

export default ChartFormatWrapper
