import Language from "language"
import React, {memo} from "react"
import {BironBreadcrumb, BironBreadCrumbTitle} from "@biron-data/react-components"
import {useNavigate} from "react-router-dom";
import {buildWorkspaceAdminUri} from "../../commons/buildWorkspaceAdminUri";
import {useSelector} from "react-redux";
import {getCurrentWorkspaceExpanded} from "../../redux/workspace.selector";

interface AdminBreadcrumbProps {
  category?: {
    title: string
    link: string
  }
  detail?: {
    title: string
    link?: string
  }
}

// eslint-disable-next-line react/display-name
const AdminBreadcrumb = memo<AdminBreadcrumbProps>(({
                                                      category, detail
                                                    }) => {
  const navigate = useNavigate()
  const workspace = useSelector(getCurrentWorkspaceExpanded)

  return <BironBreadcrumb items={[
    {
      title: <BironBreadCrumbTitle onClick={() => {
        if (workspace) {
          navigate(buildWorkspaceAdminUri(workspace))
        }
      }}>{Language.get("admin.title")}</BironBreadCrumbTitle>,
    },
    ...(category ? [{
      title: category.link ? <a onClick={() => {
        navigate(category.link!)
      }}>{category.title}</a> : category.title,
    }] : []),
    ...(detail ? [{
      title: detail.link ? <a onClick={() => {
        navigate(detail.link!)
      }}>{detail.title}</a> : detail.title,
    }] : [])
  ]
  }/>
})

export default AdminBreadcrumb

