import React, {FunctionComponent, PropsWithChildren} from "react"
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {SortDescendingIcon} from "@heroicons/react/outline"

interface Props extends PropsWithChildren {
  name: string
}

const OrderBysWrapper: FunctionComponent<Props> = ({children, name}) => (
  <WrapperRoot {...{
    icon: SortDescendingIcon,
    label: "configuration-label-orderBys",
    description: "configuration-description-orderBys",
  }}>
    <WrapperItem name={name}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

export default OrderBysWrapper
