import React, {FunctionComponent} from 'react'
import {Props} from "components/forms/confItems/ChartTypeWrapper"
import {WrapperItem, WrapperRoot} from "@biron-data/react-components";
import Union from "themes/svg/union.svg?react";

export const BoundariesWrapper: FunctionComponent<Props> = ({children, name}) => <WrapperRoot icon={Union}
                                                                                               label="stroke">
    <WrapperItem name={name}>
        {children}
    </WrapperItem>
</WrapperRoot>