import {useCallback} from "react";
import {ViewDto} from "@biron-data/bqconf/dist/MetaModel";
import {DatamodelDto, MetaModel} from "@biron-data/bqconf";

export const useGetOriginalData = (datamodel?: DatamodelDto) => useCallback(<T extends {
  code: string
}, >(keyOfViewDto: keyof ViewDto, getter: (viewCode: string, code: string) => T | undefined) => {
  const map = new Map<string, T>();

  for (const restrictedView of datamodel?.restrictedViews ?? []) {
    for (const code of restrictedView[keyOfViewDto]) {
      const element = getter(restrictedView.code, code);
      if (element && !map.has(element.code)) {
        map.set(element.code, element);
      }
    }
  }

  return Array.from(map.values());
}, [datamodel?.restrictedViews])

export const useGetMetric = (metamodel: MetaModel | null) => useCallback((viewcode: string, metricCode: string) => {
  return metamodel?.getView(viewcode)?.metrics.find(m => m.code === metricCode)
}, [metamodel])

export const useGetDimension = (metamodel: MetaModel | null) => useCallback((viewcode: string, dimensionCode: string) => {
  return metamodel?.getView(viewcode)?.dimensions.find(d => d.code === dimensionCode)
}, [metamodel])