import React, {FunctionComponent} from 'react'
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import {XIcon} from "@heroicons/react/outline"

interface Props {
  children: React.ReactNode
  name: string
}

const LimitsWrapper: FunctionComponent<Props> = ({children, name}) => (
  <WrapperRoot icon={XIcon}
               label="configuration-label-limits">
    <WrapperItem name={name}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

export default LimitsWrapper