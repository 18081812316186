import React, {useMemo, useRef, useState} from "react"
import {
  AdministrationLayout,
  BironTabs,
  ColorizedTag,
  ContentContainer,
  IconContainer,
  InformationsContainer,
  BironList,
  TableLink,
  JustifyContentProperty,
} from "@biron-data/react-components";
import DetailsInformations from "../dataSourceDoc/details/Details.Informations";
import Language from "../../language";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getCurrentMetaModel, getDataModels} from "../../redux/environment.selector";
import {DataSourceObjectType} from "../dataSourceDoc/DataSource.types";
import {useTypeRepresentationColor} from "../dataSourceDoc/DataSource.hooks";
import {getAvailables, getAvailablesForAce, getCurrentWorkspaceExpanded} from "../../redux/workspace.selector";
import {ExternalLinkIcon} from "@heroicons/react/solid";
import {buildWorkspaceUri} from "../../commons/buildWorkspaceUri";
import {filterData, Search, SearchRef} from "../search/Search";
import {ConsolidatedDimension, MetricDef} from "@biron-data/bqconf";
import {buildWorkspaceAdminWorkspaceUri} from "../../commons/buildWorkspaceAdminUri";
import {ExpandedWorkspace} from "../../redux/models/workspace";
import AdminBreadcrumb from "../admin/Admin.Breadcrumb";
import {useGetDimension, useGetMetric, useGetOriginalData} from "./DatamodelDetail.hooks";
import styled from "styled-components";

const DatamodelDetail = () => {
  const params = useParams()
  const {id} = params
  const getTypeRepresentationColor = useTypeRepresentationColor()
  const datamodels = useSelector(getDataModels)
  const workspacesForAce = useSelector(getAvailablesForAce)
  const workspaces = useSelector(getAvailables)
  const currentWorkspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
  const metamodel = useSelector(getCurrentMetaModel)
  const getMetric = useGetMetric(metamodel)

  const getDimension = useGetDimension(metamodel)

  const datamodel = datamodels.find(d => d.code === id)
  const searchRef = useRef<SearchRef>(null)

  const getOriginalData = useGetOriginalData(datamodel)

  const originalMetrics = useMemo(() => {
    return getOriginalData<MetricDef>("metricCodes", getMetric)
  }, [getMetric, getOriginalData])

  const originalDimensions = useMemo(() => {
    return getOriginalData<ConsolidatedDimension>("dimensionCodes", getDimension)
  }, [getDimension, getOriginalData])

  const [metrics, setMetrics] = useState<(MetricDef | undefined)[]>(originalMetrics)
  const [dimensions, setDimensions] = useState<(ConsolidatedDimension | undefined)[]>(originalDimensions)

  const options = useMemo(() => {
    return [...metrics.map((metric) => ({
      key: metric?.code ?? '',
      elements: [
        {
          content: <div style={{display: "flex", flexDirection: 'column'}}>
            <div style={{color: "var(--primary)"}}>{metric?.alias}</div>
            <div>{metric?.description}</div>
          </div>,
          span: 22,
        },
        {
          content: <div>
            <ColorizedTag
              text={Language.get("admin.dataDoc.details.type", DataSourceObjectType.METRIC)}
              color={getTypeRepresentationColor(DataSourceObjectType.METRIC)}/>
          </div>,
          span: 2,
          align: JustifyContentProperty.end
        }
      ],
      actions: []
    })), ...dimensions.map((dimension) => ({
      key: dimension?.code ?? '',
      elements: [
        {
          content: <div style={{display: "flex", flexDirection: 'column'}}>
            <div style={{color: "var(--primary)"}}>{dimension?.alias}</div>
            <div>{dimension?.description}</div>
          </div>,
          span: 22,
        },
        {
          content: <div>
            <ColorizedTag
              text={Language.get("admin.dataDoc.details.type", DataSourceObjectType.DIMENSION)}
              color={getTypeRepresentationColor(DataSourceObjectType.DIMENSION)}/>
          </div>,
          span: 2,
          align: JustifyContentProperty.end
        }
      ],
      actions: []
    }))]
  }, [dimensions, getTypeRepresentationColor, metrics])

  const [pagination, setPagination] = useState({
    offset: 0,
    pageSize: 150
  })

  return <AdministrationLayout breadcrumb={
    <AdminBreadcrumb
      category={{
        title: Language.get("admin.datamodels.breadcrumb.title"),
        link: 'admin/datamodels'
      }} detail={{
      title: datamodel?.alias ?? ""
    }}/>
  }>
    <InformationsContainer>
      <DetailsInformations
        description={datamodel?.description ?? ""}
        id={datamodel?.code ?? ""}/>
    </InformationsContainer>
    <ContentContainer>
      <BironTabs type="card" items={[{
        key: "1",
        label: Language.get("admin.datamodels.content.objects"),
        children: <TabContentContainer>
                <Search ref={searchRef} onChange={(newValues) => {
                  setMetrics(filterData(originalMetrics, (record) => [
                    record?.alias,
                  ], newValues))
                  setDimensions(filterData(originalDimensions, (record) => [
                    record?.alias,
                  ], newValues))
                }} width={'calc(100% - 25px)'} searchedFieldKey={"admin.user.columns.user"}/>
              <BironList options={options.slice(0, pagination.pageSize + pagination.offset)} onPaginationChange={(pa) => setPagination(pa)} />
            </TabContentContainer>,
      }, {
        key: "2",
        label: Language.get("admin.datamodels.content.workspace"),
        children: <>
              <BironList options={workspaces.filter(w => w.datamodelCode === id && workspacesForAce.find(wForAce => wForAce.id === w.id)).map(w => {
                return ({
                  key: "1",
                  elements: [
                    {
                      content: <TableLink link={buildWorkspaceAdminWorkspaceUri(currentWorkspace, `${w.id}`)}>{w.name}</TableLink>,
                      span: 22,
                    },
                    {
                      content: <IconContainer key={`${w.id}-open`} clickable={true} onClick={() => {
                        window.open(buildWorkspaceUri(w), '_blank')
                      }}>
                        <ExternalLinkIcon/>
                      </IconContainer>,
                      span: 2,
                      align: JustifyContentProperty.end
                    }
                  ],
                  actions: []
                })
              })}/>
        </>,
      }]}>
      </BironTabs>
    </ContentContainer>
  </AdministrationLayout>
}

export default DatamodelDetail

const TabContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
`