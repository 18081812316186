import React, {forwardRef, FunctionComponent, PropsWithChildren} from 'react'
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import ChartTypeSelector from '../selector/chartType/ChartTypeSelector'

export interface Props extends PropsWithChildren {
  name: string,
}

const ChartTypeWrapper: FunctionComponent<Props> = ({children, name}) => <WrapperRoot>
  <WrapperItem name={name}>
    {children}
  </WrapperItem>
</WrapperRoot>

export default ChartTypeWrapper