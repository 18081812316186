import React, {useMemo, useRef, useState} from 'react'
import {TooltipPopover, IconContainer} from "@biron-data/react-components"
import Language from "language"
import {ChartSquareBarIcon, CogIcon} from "@heroicons/react/outline"
import styled from "styled-components"
import ChartSummary from "components/charts/Chart.Summary"
import {getResolvedFromPeriod} from "@biron-data/period-resolver"
import {useRequirements} from "@biron-data/react-bqconf"
import {MetaModel, DataSelection, DatamodelDto} from "@biron-data/bqconf"
import {Col, Row} from "antd"
import {ChartPreview} from "components/forms/confItems/ChartPreview"
import {CHART_PADDING_X, CHART_PADDING_Y} from "components/charts/Chart.constants"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {SizeMe} from "react-sizeme"
import {useExportEffectiveChartConf} from "classes/workflows/query-workflows/QueryWorkflow"
import {useSelector} from "react-redux"
import {getPeriods} from "redux/period.selector"
import {Export} from "components/forms/Form.types"
import {useExportConfModel, usePeriodFromCode} from "components/admin/exports/ExportManager.utils"
import _ from "lodash"
import {useLanguageResolver} from "@biron-data/react-contexts"
import {NormalizedDashboardTypes} from "../../../schemas/dashboard";

interface ExportManagerProps {
  metaModel: MetaModel
  dashboard: Pick<NormalizedDashboardTypes, "id" | "title">
  datamodel: DatamodelDto,
  environmentId: number
  onConfEdit: () => void
  data: Export
}

const ExportManagerInformation = ({onConfEdit, metaModel, environmentId, datamodel, dashboard, data}: ExportManagerProps) => {
  const {title, metrics, filters} = data
  const languageResolver = useLanguageResolver()
  const chartContainer = useRef<any>()
  const periods = useSelector(getPeriods)
  const [isPreviewDisplayed, setIsPreviewDisplayed] = useState(false)

  const period = usePeriodFromCode(periods, data.period.code)

  const dashboardSelection: DataSelection = useMemo(() => ({
    filters: [],
    slicers: [],
    initTimestamp: Date.now(),
    date: getResolvedFromPeriod(period),
  }), [period])

  const effectiveConf = useExportEffectiveChartConf(metaModel, datamodel, period, metrics, data.slicers, filters, data.orderBys, languageResolver)

  const summary = useMemo(() => <ChartSummary effectiveConf={effectiveConf}/>, [effectiveConf])

  const {
    availableDimensions,
    availableViews: viewsWithMetrics,
  } = useRequirements(metaModel,
    datamodel,
    null,
    data.slicers,
    data.metrics)

  const previewData = useExportConfModel(data, period, availableDimensions)

  const preview = useMemo(() => {
    return <SizeMe monitorHeight monitorWidth>{({size}) => <PreviewContainer ref={chartContainer} $isdisplayed={isPreviewDisplayed ? 1 : 0}>
      {chartContainer && chartContainer.current && <ChartPreview {...{
        metaModel,
        dashboard,
        datamodel,
        environmentId,
        dimensions: {
          width: (size.width ?? 0) - (CHART_PADDING_X * 2) - 20,
          height: 550 - (CHART_PADDING_Y * 2) - 25,
        },
        data: previewData,
        type: WidgetTypes.EXPORT,
        dashboardSelection,
        availableDimensions,
        viewsWithMetrics,
        isMultiView: !_.uniqBy(data.metrics, "viewCode"),
      }}
      />}
    </PreviewContainer>}
    </SizeMe>
  }, [availableDimensions, dashboard, dashboardSelection, data.metrics, datamodel, environmentId, isPreviewDisplayed, metaModel, previewData, viewsWithMetrics])

  return <Container>
    <Row>
      <Col span={22} ref={chartContainer}>
        {title}
        <SummaryContainer>
          {summary}
        </SummaryContainer>
        {isPreviewDisplayed && preview}
      </Col>
      <FlexCol span={2}>
        <FlexRow>
          <TooltipPopover size={'small'} content={Language.get('configuration')}>
            <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><CogIcon className="disable-grab"
                                                                                                            onClick={onConfEdit}/></IconContainer>
          </TooltipPopover>
          <IconContainer clickable={true} hoverColor={"var(--primary-light)"}
                         color={isPreviewDisplayed ? "var(--primary-light)" : undefined} containerSize={28}
                         onClick={() => setIsPreviewDisplayed(!isPreviewDisplayed)}><ChartSquareBarIcon/></IconContainer>
        </FlexRow>
      </FlexCol>
    </Row>
  </Container>
}

export default ExportManagerInformation

const SummaryContainer = styled.div`
  overflow-x: scroll;
  padding-bottom: 10px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  `

const FlexRow = styled.div`
  display: flex;
  justify-content: end;
  `
const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  `

const PreviewContainer = styled.div<{ $isdisplayed: number }>`
  width: 100%;
  padding: 15px 10px 10px;
  align-items: center;
  justify-content: center;
    display: ${({$isdisplayed}) => $isdisplayed ? 'block' : 'none'};
`