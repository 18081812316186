import {useCallback, useMemo} from "react";
import {UACWithAces} from "../../hooks/useUACWithAces";
import {NormalizedWorkspaceDetail} from "../../schemas/workspace";
import {Roles} from "../admin/user/UserManager.types";

export const useUacWithCurrentWorkspaceAces = (
  id: string | undefined,
  uacWithACES: UACWithAces[],
  workspace?: NormalizedWorkspaceDetail) => useMemo(() => uacWithACES.map(uac => {
    return {
        ...uac,
        user: {
            ...uac.user,
            aces: uac.user.aces.filter(ace => ace.targetId === id || ace.targetId === workspace?.datamodelCode),
        }
    }
}), [id, uacWithACES, workspace?.datamodelCode])

export const useOnUpdateWorkspaceRights = (
  handleOnConfirm: (actualUac: UACWithAces, newFormData: {[p: string]: Roles[] | undefined }) => Promise<void>,
  ) => useCallback((actualUac: UACWithAces, newRoles: Roles[], workspaceId: number) => {
    return handleOnConfirm(actualUac, {
        [`workspace:${workspaceId}`]: newRoles
    })
}, [handleOnConfirm])